@import '../../../../css/variables.scss';

.container {
  height: 100%;
}

.description {
  margin-top: $sp-xl;
  height: 90%;
  overflow: auto;
  padding-right: $sp-m;
}

@media  #{$mobile-cookie-banner-break-points} {
  .container {
    overflow: auto;
  }
  
  .description {
    height: auto;
    overflow: visible;
  }    
}
