@import '../../../css/variables';

.assistive_text {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.draggable_list {
  list-style-type: none;
  padding: 0;
  
  &:focus {
    outline: 2px solid $color-blue;
  }

  > *:focus {
    outline: 2px solid $color-blue;
  }
}