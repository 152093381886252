@import '../../css/variables';

.textfieldContainer {
  border: $border;
  margin: 0;
  padding: 0;
  box-shadow: $box-shadow-default;
}

.textField {
  display: block;
  padding: $sp-l $sp-xl;
  font-size: $fs-m;
  border: none;
  outline: none;
  transition: $box-shadow-transition;
  box-sizing: border-box;
  width: 100%;

  &:hover, &:focus {
    box-shadow: $box-shadow-focus;
  }

  :global(.hasTouch) &:focus, :global(.hasTouch) &:hover {
    box-shadow: $box-shadow-default;
  }
}
