@import '../../../css/variables.scss';

.backButton {
  background: none;
  border: none;
  display: flex;
  color: #246DD7;
  flex-direction: row;
  font-size: $fs-s;
  padding: 0 1px 0 0;
  text-decoration: none;
  line-height: $sp-xxl;
  align-items: center;

  span {
    margin-left: $sp-xs;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    border: none;
    background: none;
  }
}

.spaceBetween {
  justify-content: space-between;
}
