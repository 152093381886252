@import '../../../../css/variables.scss';

.container {
  height: 100%;
}

.description {
  margin-top: $sp-xl;
  height: 90%;
  overflow: auto;
  padding-right: $sp-m;
  font-size: $fs-s;
  line-height: 22px;
}

.row {
  margin-top: 0;
}

.title {
  font-weight: bold;
  margin-right: $sp-xs;
}

@media  #{$mobile-cookie-banner-break-points} {
  .container {
    overflow: auto;
  }

  .description {
    height: auto;
    overflow: visible;
  }
}
