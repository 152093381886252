@import '../../../css/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.description {
  height: 220px;
  margin-top: $sp-xl;
  overflow: auto;
  padding-right: $sp-m;
}

.sectionItems {
  display: flex;
  flex-direction: column;
  height: 64px;
  min-height: 64px;
  justify-content: space-around;
  margin-bottom: $sp-l;
  margin-top: 6px;

  a {
    background: none;
    border: none;
    color: #246DD7;
    display: flex;
    flex-direction: row;
    font-size: $fs-s;
    text-decoration: none;
    padding: 2px 0 2px 0;

    span {
      margin-right: $sp-xs;
    }
  }

  a:hover:focus {
    outline: auto !important;
  }
}

.categoryItem {
  align-items: center;
  background-color: #eaf3ff;
  border: none;
  border-radius: 8px;
  color: #2B3449;
  display: flex;
  flex-direction: row;
  font-size: $fs-xss;
  font-weight: bold;
  min-height: 48px;
  height: 48px;
  justify-content: space-between;
  margin-bottom: $sp-xxl;
  padding-left: $sp-xl;
  padding-right: $sp-xl;
  text-decoration: none;
}

.acceptButton {
  border-radius: 18px;
  font-size: $fs-xss;
  height: 36px;
  padding: 0px;
  font-weight: bold;
}

.linkButton {
  padding: 0px;
  line-height: $sp-xxl;
  display: flex;
  align-items: center;
}

@media #{$mobile-only} {
  .sectionItems {
    margin-bottom: 12px;
    margin-top: 10px;
  }

  .categoryItem {
    margin-bottom: $sp-l;
  }

  .description {
    height: 150px;
    margin-top: 14px;
  }
}

@media  #{$mobile-cookie-banner-break-points} {
  .sectionItems {
    margin-top: 14px;
    margin-bottom: 16px;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .description {
    height: auto;
    overflow: auto;
    flex-shrink: 0;
  }
}
