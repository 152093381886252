// color
$color-white: #FFFFFF;
$color-black-blue: #213144;
$color-blue-light: #f2f9fc;
$color-black-blue-darker: darken($color-black-blue, 20%);
$color-black-logo: #364045;
$color-red: #E03131;
$color-green: #5DC95B;
$color-blue: #1B6EC2;
$color-blue-dark: darken($color-blue, 10%);
$color-gray: #E2E2E2;
$color-gray-light: #DAE0EA;
$color-gray-dark: darken($color-gray, 10%);
$color-gray-black: #5b6578;
$color-gray-meta: #6B7588;
$color-denim: #1B75BC;
$color-mobile-tap: rgba($color-blue, 0.1);
$color-yellow: #FFFAB0;

// transition
$ease-in-out-transition: 0.15s ease-in-out;
$ease-transition: 0.15s ease;
$linear-transition: 0.2s linear;

//common spacing
$base-spacing: 20px;
$half-spacing: $base-spacing / 2;
$double-spacing: $base-spacing * 2;
$base-spacing-mobile: 13px;
$base-portrait-spacing-header: 180px;
$base-landscape-spacing-header: 120px;

// background-color
$background-color-transition: background-color $linear-transition;

// border
$border-width: 1px;
$border: $border-width solid $color-gray-light;

// box shadow
$box-shadow-focus: 0px 0px 0px 3px $color-blue;
$box-shadow-default: 0px 1px 3px 0px rgba(216,216,216,0.60);
$box-shadow-transition: box-shadow $ease-in-out-transition;

// @media size
$mobile-tablet: "screen and (max-width: 600px)";
$mobile-only: "screen and (max-width: 480px)";
$mobile-only-landscape: "screen and (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape)";

// // 663 is the header's height size, we need it to display the banner properly
$mobile-cookie-banner: "screen and (max-height: 667px)";
$mobile-cookie-banner-portrait: "screen and (max-height: 667px) and (max-width: 812px)";
$cookie-banner-language-dropdown-centered: #{$mobile-only-landscape}, #{$mobile-only}, #{$mobile-cookie-banner-portrait};
// For iphone > 7, 7+ keeps the scrollbar in the description
$mobile-cookie-banner-break-points: "screen and (max-height: 550px)";
$mobile-cookie-banner-with-wrapper: #{$mobile-only}, #{$mobile-cookie-banner-portrait}, #{$mobile-cookie-banner}, #{$mobile-only-landscape};
$internet-explorer-only: "screen\0";

//4px based spacing
$sp-n: 0;
$sp-xxs: 2px;
$sp-xs: 4px;
$sp-s: 8px;
$sp-m: 12px;
$sp-l: 16px;
$sp-xl: 20px;
$sp-xxl: 24px;
$sp-xxxl: 32px;
$sp-xxxxl: 36px;
$sp-xxxxxl: 40px;

//font size
$fs-xxs: 10px;
$fs-xs: 12px;
$fs-xss: 14px;
$fs-s: 16px;
$fs-m: 18px;
$fs-ml: 24px;
$fs-l: 26px;
$fs-xl: 28px;

// checkbox, radio for input
$indicator-dimensions: 24px;

$line-height: 1.2;

$z-index-survey-body: 3000;
$z-index-survey-body-input-layer: 3500;
$z-index-dropdown: 4000;
$z-index-calendar: $z-index-dropdown + 100;
$z-index-cookie-banner: 4500;
$z-index-language-dropdown: 4600;
$z-index-modal: 5000;
$z-index-top-layer: 10000;

// height of option units like single-select option or rating bar option
$option-min-height: 56px;

// Fix for text zoom in Safari, consider applying rem units to every component in the future
$sp-l-rem: 0.85rem;
$sp-xl-rem: 1.25rem;
$option-min-height-rem: 3.5rem;
$option-min-height-rem-2: 3.55rem;
