@import '../../css/variables';

.textareaContainer {
  border: $border;
  margin: 0;
  padding: 0;
  box-shadow: $box-shadow-default;
  transition: $box-shadow-transition;

  &:hover {
    border: $border-width solid transparent;
    box-shadow: $box-shadow-focus;
  }

  :global(.hasTouch) &:hover {
    border: $border;
    box-shadow: $box-shadow-default;
  }
}

.textareaContainerOverflowHidden {
  overflow: hidden;
  position: relative;
}

.textareaContainer_isFocused {
  border: $border-width solid transparent;
  box-shadow: $box-shadow-focus;

  :global(.hasTouch) & {
    border: $border;
    box-shadow: none;
  }
}

.textarea {
  min-height: 150px;
  resize: none;
  border: 0;
  padding-bottom: $sp-xxxl;
}

.textarea {
  display: block;
  padding: $sp-l $sp-xl;
  font-size: $fs-m;
  border: none;
  outline: none;
  box-sizing: border-box;
  width: 100%;
}

.shortenedTextarea {
  font-size: $fs-xss;
  height: calc(55px - 22px);
  margin: 11px 12px;
  word-break: break-word;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow:hidden;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

.textareaHidden {
  display: none;
}

.textareaCount {
  position: absolute;
  right: 0;
  font-size: $fs-xs;
  text-align: right;
  padding-top: $half-spacing;
  padding-right: $half-spacing;
  opacity: 0.50;

  :global(.rtl) & {
    left: 0;
    right: inherit;
    padding-left: $half-spacing;
    padding-right: 0;
  }
}

.textareaCharacterTyped {
  font-size: 0px;
}
@media #{$mobile-tablet} {
  .textArea {
    padding: $sp-m 14px $sp-xxxl 14px;
  }

  .textareaCount {
    padding-right: 0;
    padding-left: 0;
  }
}
