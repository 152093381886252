@import '../../../../../css/variables.scss';

.details {
  background: $color-blue-light;
  border-radius: $sp-m;
  padding: $sp-m;
  margin-top: $sp-xxl;
  margin-bottom: $sp-m;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.title {
  font-weight: bold;
  font-size: $fs-xss;
  margin-right: $sp-m;
  margin-bottom: $sp-xl;
  flex-shrink: 0;
  max-width: 50%;
}

.info {
  font-size: $fs-xss;
  padding-right: $sp-xs;
}
