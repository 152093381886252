@import '../../css/variables';

$_opacity: 0.55;
$_group-spacing: $sp-l / 2;

.multiSelectMessage {
  position: absolute;
  bottom: 2px; // offset line-height of questionTitle to center it
  line-height: $sp-xxxl;
  opacity: 0;
  margin: 0 0 0 -3px; // offset -3px because of icon
  transform: translateX(-$sp-xl);
  transition: transform $ease-in-out-transition, opacity $ease-in-out-transition;

  :global(.rtl) & {
    margin: 0;
    transform: translateX($sp-xl);
  }

  :global(.hasTouch) & {
    opacity: $_opacity;
    transform: none;
  }
}

.multiSelectMessageHidden {
  display: none;
}

.longformContainer:hover .multiSelectMessage, .multiSelectMessage_isShown {
  transform: translateX(0);
  opacity: $_opacity;
}

.groupContainer {
  display: table;
  border-collapse: separate;
  table-layout: fixed;
  width: 100%;
}

.groupContainerHorizontal {
  display: table;
  border-collapse: separate;
  width: 100%;
}

.group {
  display: table-cell;
  vertical-align: top;

  &:nth-child(1) {
    padding-right: $_group-spacing;
  }

  &:nth-child(2) {
    padding-left: $_group-spacing;
    padding-right: $_group-spacing;
  }

  &:nth-child(3) {
    padding-left: $_group-spacing;
  }

  &:only-child {
    padding-right: 0;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

@media #{$mobile-tablet} {
  .longformContainer:hover .multiSelectMessage {
    transform: translateX(0);
    opacity: $_opacity;
  }

  .multiSelectMessage {
    transform: translateX(0);
    opacity: $_opacity;

    :global(.rtl) & {
      margin-right: $sp-xl;
    }
  }

  .groupContainer {
    display: block;
  }

  .group {
    display: block;
    padding-bottom: $sp-xxl;

    &:nth-child(1) {
      padding-right: 0;
    }

    &:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
    }

    &:nth-child(3) {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
