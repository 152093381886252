@import '../../css/variables';

.questionTitle {
  position: relative;
  padding: $sp-xxxl $sp-n $sp-xxxl $sp-n;
  font-size: $fs-xl;
  line-height: 38px;
  width: 100%;
}

.questionTitle_hasNoCaption {
  padding-bottom: 0;
}

@media #{$mobile-tablet} {
  .questionTitle {
    font-size: $fs-ml;
    line-height: 32px;
  }
}
