@import '../../css/variables.scss';

.wrapper {
  z-index: $z-index-cookie-banner;
}

.container {
  background-color: #ffffff;
  box-shadow: 0px 6px 8px 1px rgba(0, 0, 0, 0.2);
  border-top-left-radius: $sp-xl;
  border: $border;
  border-radius: 16px 0 0 0;
  bottom: 0;
  height: 518px;
  padding: $sp-xl;
  position: fixed;
  right: 0;
  width: 300px;
  z-index: $z-index-cookie-banner;
}

.header {
  height: 28px;
}

.body {
  height: 490px;
}

@media #{$mobile-cookie-banner-with-wrapper} {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.4);
  }

  .container {
    bottom: 0px;
    width: 250px;
    border-radius: 0px;
    height: calc(100vh - #{$base-portrait-spacing-header} - 32px - 2px); // 32 paddings and 2 borders
    max-height: 518px;
    right: 0;
    left: 0;
    margin: auto;
    padding: $sp-l;
  }

  .header {
    height: 26px;
  }

  .body {
    height: calc(100% - 26px)
  }
}

@media #{$mobile-only-landscape} {
  .container {
    height: calc(100vh - #{$base-landscape-spacing-header} - 32px - 2px); // 32 paddings and 2 borders
  }
}
