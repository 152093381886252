@import '../../../css/variables';

:global(.anchor) {
  font-size: $fs-xss;
  vertical-align: bottom;
  text-align: center;
  overflow-wrap: break-word;
  padding-left: $sp-xxs;
  padding-right: $sp-xxs;
}

:global(.anchor_isJustified) {
  &:first-child, :global(.rtl) &:last-child {
    text-align: left;
  }

  &:last-child, :global(.rtl) &:first-child {
    text-align: right;
  }
}

:global(.anchor_isNaOption) {
  overflow: hidden;
  height: 0px;
  line-height: 0px;
  text-align: center;
  padding-bottom: 0px;
}

:global(.anchor_isForcedShown), :global(.anchor_isOnFirstRow) {
  display: table-cell;
  height: initial;
  line-height: 1.2;
  padding-bottom: $sp-l;
}

@media #{$mobile-tablet} {
  :global(.anchor) {
    padding-bottom: $sp-l;
  }
}
