@import "../../css/variables";

$_transition: 0.4s linear;

.qualityMeterContainer {
  display: table;
  border-top: $border-width solid $color-gray-light;
  color: $color-gray;
  font-size: $fs-xss;
  height: $sp-xxxl;
  opacity: 0;
  transform: translateY(100%);
  transition: transform $_transition, opacity 0.3s linear;
  width: 100%;
}

.qualityMeterContainer_isActive {
  opacity: 1;
  transform: translateY(0);

  .qualityMeterLabel {
    color: $color-gray-black;
  }
}

.qualityMeterLabel {
  display: table-cell;
  vertical-align: middle;
  font-weight: 600;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 30%;
}

.qualityMeterProgressBar {
  display: table-cell;
  vertical-align: middle;
}

@media #{$mobile-tablet} {
  .qualityMeterContainer {
    font-size: $fs-xs;
  }

  .qualityMeterLabel {
    width: 30%;
  }

  .qualityMeterProgressBar {
    width: 65%;
  }
}
